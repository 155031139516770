import React from "react";
import Button from "../components/Button";
import useCrisp from "../hooks/components/crispHook";
import { 
  ArrowRight, 
  BookOpen, 
  MessageCircle, 
  Lightbulb, 
  FileSpreadsheet, 
  Filter, 
  Upload, 
  Download, 
  ExternalLink
} from 'lucide-react';

interface VideoCardProps {
  videoUrl: string;
}

const VideoCard: React.FC<VideoCardProps> = ({ videoUrl }) => {
  return (
    <div className="w-full max-w-2xl mx-auto rounded-xl overflow-hidden shadow-lg my-4 bg-white">
      <div className="relative w-full aspect-video">
        <iframe
          className="w-full h-full"
          src={videoUrl}
          title="Integration Guide"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    </div>
  );
};

function OnboardingThree() {
  useCrisp();

  const bookCall = () => {
    window.open("https://tidycal.com/lupapps", "_blank");
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#cbeec5] to-white pb-12">
      <div className="container max-w-[1000px] mx-auto px-6 py-10">
        {/* Main Content Card */}
        <div className="bg-white rounded-2xl shadow-xl p-8 mb-10">
          {/* Video Section */}
          <div className="mb-10">
            <div className="flex items-center mb-5">
              <Lightbulb className="w-7 h-7 text-blue-600 mr-3" />
              <h2 className="text-2xl font-bold">Quick Start Guide</h2>
            </div>
            <VideoCard videoUrl="https://www.youtube.com/embed/HLsJTlr0co8" />
            <p className="text-center text-sm text-gray-600 mt-3">
              Watch this 2-minute video to learn the basics
            </p>
          </div>

          {/* Key Features */}
          <div className="grid md:grid-cols-2 gap-6 mb-10">
            {/* Embedding Sheets Section - Entire section clickable */}
            <a
              href="https://gsi.crisp.help/en/article/embed-google-sheets-into-mondaycom-1ssvary"
              className="block bg-blue-50 p-6 rounded-xl hover:bg-blue-100 transition-all duration-200 transform hover:scale-[1.01] hover:shadow-md"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="flex items-center justify-between mb-6">
                <div className="flex items-center">
                  <FileSpreadsheet className="w-6 h-6 text-blue-600 mr-3" />
                  <h3 className="font-bold text-xl">Embedding Sheets</h3>
                </div>
                <ExternalLink className="w-5 h-5 text-blue-600" />
              </div>
              <ol className="space-y-5">
                <li className="flex items-start">
                  <div className="flex-shrink-0 w-6 h-6 text-blue-600 rounded-full mr-3 bg-blue-100 flex items-center justify-center text-xs font-medium">
                    1
                  </div>
                  <div className="flex-1">
                    <p className="font-medium">Copy Sheet URL</p>
                    <p className="text-sm text-gray-600 mt-1">Open your Google Sheet and copy its URL</p>
                  </div>
                </li>

                <li className="flex items-start">
                  <div className="flex-shrink-0 w-6 h-6 bg-blue-100 text-blue-600 rounded-full flex items-center justify-center mr-3 text-xs font-medium">
                    2
                  </div>
                  <div className="flex-1">
                    <p className="font-medium">Open Settings</p>
                    <p className="text-sm text-gray-600 mt-1">Click the settings icon in your monday.com board</p>
                  </div>
                </li>

                <li className="flex items-start">
                  <div className="flex-shrink-0 w-6 h-6 bg-blue-100 text-blue-600 rounded-full flex items-center justify-center mr-3 text-xs font-medium">
                    3
                  </div>
                  <div className="flex-1">
                    <p className="font-medium">Paste & Connect</p>
                    <p className="text-sm text-gray-600 mt-1">Paste the shared URL to embed your sheet</p>
                  </div>
                </li>
              </ol>
            </a>

            {/* Automation Types Section */}
            <div className="bg-purple-50 p-6 rounded-xl">
              <div className="flex items-center justify-between mb-6">
                <h3 className="font-bold text-xl">Automation Types</h3>
              </div>
              <ul className="space-y-3">
                <li>
                  <a 
                    href="https://gsi.crisp.help/en/article/selective-data-exports-to-google-sheets-1h3jqs3/"
                    className="flex items-start p-2 -mx-1 rounded-lg hover:bg-purple-100 transition-colors"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Filter className="flex-shrink-0 w-5 h-5 text-purple-600 mr-3 mt-0.5" />
                    <div className="flex-1">
                      <p className="font-medium">Selective Exports</p>
                      <p className="text-sm text-gray-600 mt-1">Export specific rows or values to cells in Google Sheets</p>
                    </div>
                    <ExternalLink className="w-4 h-4 text-purple-600 mt-1 ml-2 flex-shrink-0" />
                  </a>
                </li>

                <li>
                  <a 
                    href="https://gsi.crisp.help/en/article/full-data-exports-to-google-sheets-11bxsoq/"
                    className="flex items-start p-2 -mx-1 rounded-lg hover:bg-purple-100 transition-colors"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Upload className="flex-shrink-0 w-5 h-5 text-purple-600 mr-3 mt-0.5" />
                    <div className="flex-1">
                      <p className="font-medium">Full Exports</p>
                      <p className="text-sm text-gray-600 mt-1">Export entire boards or groups to new or existing spreadsheets</p>
                    </div>
                    <ExternalLink className="w-4 h-4 text-purple-600 mt-1 ml-2 flex-shrink-0" />
                  </a>
                </li>

                <li>
                  <a 
                    href="https://gsi.crisp.help/en/article/data-imports-from-google-sheets-1hsowzu/"
                    className="flex items-start p-2 -mx-1 rounded-lg hover:bg-purple-100 transition-colors"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Download className="flex-shrink-0 w-5 h-5 text-purple-600 mr-3 mt-0.5" />
                    <div className="flex-1">
                      <p className="font-medium">Data Imports</p>
                      <p className="text-sm text-gray-600 mt-1">Import spreadsheet data into monday.com boards</p>
                    </div>
                    <ExternalLink className="w-4 h-4 text-purple-600 mt-1 ml-2 flex-shrink-0" />
                  </a>
                </li>
              </ul>
            </div>
          </div>

          {/* Quick Links */}
          <div className="grid md:grid-cols-2 gap-6">
            <a 
              href="https://gsi.crisp.help"
              className="flex items-center justify-between p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-all duration-200 transform hover:scale-[1.02]"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="flex items-center">
                <BookOpen className="w-5 h-5 text-blue-600 mr-3" />
                <span className="font-medium">Documentation</span>
              </div>
              <ArrowRight className="w-4 h-4 text-blue-600" />
            </a>

            <button 
              onClick={bookCall}
              className="flex items-center justify-between p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-all duration-200 transform hover:scale-[1.02]"
            >
              <div className="flex items-center">
                <MessageCircle className="w-5 h-5 text-blue-600 mr-3" />
                <span className="font-medium">Schedule a Demo</span>
              </div>
              <ArrowRight className="w-4 h-4 text-blue-600" />
            </button>
          </div>
        </div>

        {/* Help Section */}
        <div className="bg-white rounded-xl p-8 text-center shadow-lg">
          <div className="flex items-center justify-center mb-4">
            <MessageCircle className="w-6 h-6 text-[#0a8134] mr-3" />
            <h3 className="text-xl font-bold">Need Help?</h3>
          </div>
          <p className="text-gray-600 mb-6 max-w-lg mx-auto">
            Our team is ready to help you set up custom solutions tailored to your specific workflow needs
          </p>
          <Button onClick={bookCall} className="bg-blue-600 hover:bg-blue-700 px-6 py-2.5 text-base">
            Book a Call
          </Button>
        </div>
      </div>
    </div>
  )
}

export default OnboardingThree;
